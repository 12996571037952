import { watch, ComputedRef, isRef } from 'vue'
import { useToast, TYPE } from 'vue-toastification'
import type { ToastOptions } from 'vue-toastification/src/types'

const watchToast = (
  message: ComputedRef<string | undefined> | string,
  opts: ToastOptions
) => {
  const toast = useToast()

  if (isRef(message)) {
    watch(message, (v) => {
      if (v) {
        toast(v, opts)
      }
    })
  } else if (message) {
    return toast(message, opts)
  }
}

export function toastError(message: ComputedRef<string | undefined> | string, opts?: ToastOptions) {
  return watchToast(message, { ...opts, type: TYPE.ERROR })
}

export function toastSuccess(message: ComputedRef<string | undefined> | string, opts?: ToastOptions) {
  return watchToast(message, { ...opts, type: TYPE.SUCCESS })
}
